// extracted by mini-css-extract-plugin
export var aboutService = "index-module--aboutService--E5-P7";
export var aboutStore = "index-module--aboutStore--gJk5G";
export var allergieInfo = "index-module--allergieInfo--zAw0J";
export var balukoLogo = "index-module--balukoLogo--i4J8N";
export var bgLeft = "index-module--bgLeft--gH6gG";
export var blandItem = "index-module--blandItem--+zuTx";
export var btnLabel = "index-module--btnLabel--miZKv";
export var btnWrap = "index-module--btnWrap--pmb82";
export var classicMenu = "index-module--classicMenu--R7ISt";
export var donutsMenu = "index-module--donutsMenu--RPCct";
export var drink = "index-module--drink--rO2LB";
export var drinkMenu = "index-module--drinkMenu--Be4Rg";
export var drinkMenuItem = "index-module--drinkMenuItem--m5ijH";
export var drinkType = "index-module--drinkType--srj9r";
export var googleMapIcon = "index-module--googleMapIcon--UVnx4";
export var horizontalLine = "index-module--horizontalLine--wh-v6";
export var imageWrap = "index-module--imageWrap--3l8Bc";
export var innerBody = "index-module--innerBody--TLr1q";
export var innerBodyTextList = "index-module--innerBodyTextList--XY35B";
export var innerHeader = "index-module--innerHeader--wC-PR";
export var innerHeaderIcon = "index-module--innerHeaderIcon--kv7Vo";
export var innerHeaderTitle = "index-module--innerHeaderTitle--nBMzj";
export var innerItem = "index-module--innerItem--+F8P-";
export var instaLogo = "index-module--instaLogo--of6+L";
export var instagram = "index-module--instagram--+sHmC";
export var instagramImageContentsWraper = "index-module--instagramImageContentsWraper--dC+Dr";
export var instagramImageList = "index-module--instagramImageList--2lcOh";
export var instagramNoteText = "index-module--instagramNoteText--y8-6G";
export var instagramTextList = "index-module--instagramTextList--Cb1RP";
export var itemList = "index-module--itemList--EYdO1";
export var itemTextMt = "index-module--itemTextMt--j2i2c";
export var leftContents = "index-module--leftContents--Gig4j";
export var leftContentsItem = "index-module--leftContentsItem--4lJX6";
export var leftImage = "index-module--leftImage--F3a8U";
export var leftItemTextList = "index-module--leftItemTextList--ul4Dl";
export var limitedMenu = "index-module--limitedMenu--X9riF";
export var limitedTitle = "index-module--limitedTitle--3O8z5";
export var mainVisual = "index-module--mainVisual--xZ1HW";
export var mapContents = "index-module--mapContents--wVk-3";
export var mapContentsHalf = "index-module--mapContentsHalf--M9dUP";
export var menuContents = "index-module--menuContents--P+s8r";
export var menuDetail = "index-module--menuDetail--WylIC";
export var menuFlavorText = "index-module--menuFlavorText--8VBxQ";
export var menuItem = "index-module--menuItem--fuQMA";
export var menuItemImage = "index-module--menuItemImage--79av-";
export var menuItemImageWrap = "index-module--menuItemImageWrap--nZcEc";
export var menuTextList = "index-module--menuTextList---zxzC";
export var noteList = "index-module--noteList--XPIBq";
export var pageLink = "index-module--pageLink--YmxbR";
export var phoneLink = "index-module--phoneLink--Ga-qc";
export var postImage = "index-module--postImage--EJ-Mi";
export var rightContents = "index-module--rightContents--QsSJP";
export var rightImage = "index-module--rightImage--laikh";
export var roundImage = "index-module--roundImage--4oENX";
export var secTitle = "index-module--secTitle--GJ1R3";
export var selfLaundry = "index-module--selfLaundry--XA3xq";
export var selfLaundryTextList = "index-module--selfLaundryTextList--9b2s1";
export var serviceContents = "index-module--serviceContents--w3mAl";
export var sns = "index-module--sns--uBFHZ";
export var snsHeader = "index-module--snsHeader--vA14H";
export var snsHeaderIcon = "index-module--snsHeaderIcon--+FV0G";
export var snsHeaderTitle = "index-module--snsHeaderTitle--x5HIm";
export var snsInfoText = "index-module--snsInfoText--ihYhc";
export var sp = "index-module--sp--f7N55";
export var spImage = "index-module--spImage--XAOCb";
export var store = "index-module--store--alqaZ";
export var storeAboutTextList = "index-module--storeAboutTextList--7kfv9";
export var storeContents = "index-module--storeContents--WeaHx";
export var storeContentsUehara = "index-module--storeContentsUehara--sQvI8";
export var storeContentsUeharaWrap = "index-module--storeContentsUeharaWrap--Q+gzz";
export var storeName = "index-module--storeName--Bf88z";
export var storeNotice = "index-module--storeNotice--uhgcA";
export var textContents = "index-module--textContents--U-lme";
export var textList = "index-module--textList--s0mgd";
export var textMt = "index-module--textMt--8oQOW";
export var toSnsBtn = "index-module--toSnsBtn--pZIan";
export var uText = "index-module--uText--BBV3k";
export var underLine = "index-module--underLine--JzQy8";
export var wayToEat = "index-module--wayToEat--jwhwo";
export var wayToEatContents = "index-module--wayToEatContents--TZQQP";