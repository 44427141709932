import React from "react"
import Layout from "components/layout/layout"
import Contents from "components/contents/index"
import * as styles from "components/index.module.scss"

const IndexPage: React.FC = () => (
  <Layout>
    <div className={styles.container}>
      <Contents />
    </div>
  </Layout>
)

export default IndexPage
