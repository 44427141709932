import React from "react";
import { useStaticQuery ,graphql } from "gatsby";
import { Swiper, SwiperSlide} from 'swiper/react';
import  SwiperCore, { Navigation,Pagination } from 'swiper';
import 'swiper/swiper.scss';
import * as styles from "components/contents/index.module.scss";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import Map from "components/googlemap/map";
import useGetPosts from "hooks/useGetPosts";
import Media from "types/media";
import { convertSpItem } from "services/convertSpItem";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

SwiperCore.use([Pagination, Navigation]);

const Contents: React.FC = React.memo(() => {
    const data = useStaticQuery(query)
    const {
      allItemJson: { nodes: items },
      allDrinkJson: { nodes: drinkMenu}
    } = data;
    const spItems = convertSpItem(items);
    const posts = useGetPosts();
    const pagination = {
      clickable: true,
    };

    const breakPoints = useBreakpoint()
    return (
      <>
        {/* First View */}
        <section className={`jp ${styles.mainVisual}`} id="top">
          <div className={styles.leftContents}>
            <div className={styles.leftContentsItem}>
              <StaticImage
                className={styles.leftImage}
                src="https://dounutbiyori.blob.core.windows.net/images/top/DB_Logo.png"
                alt=""
                formats={["auto", "webp", "avif"]}
              />
              <ul className={`fwb ${styles.leftItemTextList}`}>
                <li><span>ふわふわ・もちもちのどうなつ店</span></li>
                <li><span>ひとつひとつ手づくりのどうなつは</span></li>
                <li><span>ひとくち食べると気分が晴れる</span></li>
                <li className={styles.itemTextMt}><span>ここはみんなが笑顔になれる場所</span></li>
                <li><span>ここはいつでもどうなつ日和</span></li>
              </ul>
            </div>
          </div>
          <div className={styles.rightContents}>
          {breakPoints.sp &&
            <StaticImage
              className={styles.rightImage}
              src="https://dounutbiyori.blob.core.windows.net/images/top/round_pic.jpg"
              alt=""
              formats={["auto", "webp", "avif"]}
            />
          }
          {breakPoints.pc &&
            <StaticImage
              className={styles.rightImage}
              src="https://dounutbiyori.blob.core.windows.net/images/top/DB_Sozai.jpg"
              alt=""
              formats={["auto", "webp", "avif"]}
            />
          }
          
          </div>
        </section>

        {/* About */}
        <section className={`jp ${styles.aboutService}`} id='about'>
          <div className={styles.serviceContents}>
            <div className={styles.leftContents}>
              <div className={styles.textContents}>
                <h2 className={`${styles.secTitle}`}>どうなつ日和について</h2>
                <ul className={styles.textList}>
                  {breakPoints.pc &&
                    <>
                      <li><span>どうなつ日和のドーナツは、ふわふわ・もちもちの食感を出すため道産小麦などこだわりの原料を使用し、店内にある工房でひとつひとつ丁寧に手作りしています。</span></li>
                      <li className={styles.textMt}><span>ドーナツはふわふわと軽い食感と控えめな甘さで何個でも食べたくなるような優しい味。お子様からご年配の方まで、みなさまで楽しんでいただけます。</span></li>
                      <li className={styles.textMt}><span>金沢にゆかりのある食材を使い、ここでしか食べられない限定メニューもご用意しております。</span></li>
                    </>
                  }
                  {breakPoints.sp &&
                    <>
                      <li><span>どうなつ日和のドーナツは、ふわふわ・もちもちの食感を出すため道産小麦などこだわりの原料を使用し、店内にある工房でひとつひとつ丁寧に手作りしています。</span></li>
                      <li className={styles.textMt}><span>ドーナツはふわふわと軽い食感と控えめな甘さで何個でも食べたくなるような優しい味。お子様からご年配の方まで、みなさまで楽しんでいただけます。</span></li>
                      <li className={styles.textMt}><span>金沢にゆかりのある食材を使い、ここでしか食べられない限定メニューもご用意しております。</span></li>
                    </>
                  }
                </ul>
              </div>
            </div> 
            {breakPoints.pc && 
              <div className={styles.rightContents}>
                <StaticImage
                  className={styles.roundImage}
                  src="https://dounutbiyori.blob.core.windows.net/images/top/round_pic.jpg"
                  alt=""
                  formats={["auto", "webp", "avif"]}
                />
              </div>
            }
          </div>
        </section>

        {/* Limited Donuts Menu  */}
        <section className={`jp ${styles.limitedMenu}`} id='donutMenu'>
          <div className={styles.donutsMenu}>
            <h2 className={styles.secTitle}>どうなつメニュー</h2>
            <p className={`fwb ${styles.allergieInfo}`}><a href="https://dounutbiyori.blob.core.windows.net/file/allergy_list.pdf">アレルギー成分表は<span className={styles.underLine}>こちら（PDF）</span>をご確認ください</a></p>
            <h3 className={styles.limitedTitle}>金沢限定</h3>
            <div className={styles.menuContents}>
                <div className={styles.menuItem}>
                  <div className={styles.menuItemImageWrap}>
                    <StaticImage
                      className={styles.menuItemImage}
                      src="https://dounutbiyori.blob.core.windows.net/images/menu/limited-menu/kaga-hojicha-cream.png"
                      alt=""
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                  <ul className={styles.menuTextList}>
                    {breakPoints.pc &&
                      <>
                        <li className={`fwb ${styles.menuFlavorText}`}><span>加賀棒ほうじ茶クリーム</span></li>
                        <li className={styles.menuDetail}><span>甘く芳ばしい香りの加賀棒茶の</span></li>
                        <li className={styles.menuDetail}><span>自家製クリームを包みました</span></li>
                      </>
                    }
                    {breakPoints.sp &&
                      <>
                        <li className={`fwb ${styles.menuFlavorText}`}><span>加賀棒ほうじ茶クリーム</span></li>
                        <li className={styles.menuDetail}><span>甘く芳ばしい香りの加賀棒茶の自家製クリームを包みました</span></li>
                      </>
                    }
                  </ul>
                </div>

                <div className={styles.menuItem}>
                  <div className={styles.menuItemImageWrap}>
                    <StaticImage
                      className={styles.menuItemImage}
                      src="https://dounutbiyori.blob.core.windows.net/images/menu/limited-menu/kaga-hojicha-creamchese-kinako.png"
                      alt=""
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                  <ul className={styles.menuTextList}>
                    {breakPoints.pc &&
                      <>
                        <li className={`fwb ${styles.menuFlavorText}`}><span>加賀棒茶とクリームチーズときなこ</span></li>
                        <li className={styles.menuDetail}><span>自家製の加賀棒茶クリームとクリームチーズ</span></li>
                        <li className={styles.menuDetail}><span>を包み、きなこをまぶしました</span></li>
                      </>
                    }
                    {breakPoints.sp &&
                      <>
                        <li className={`fwb ${styles.menuFlavorText}`}><span>加賀棒茶とクリームチーズときなこ</span></li>
                        <li className={styles.menuDetail}><span>自家製の加賀棒茶クリームとクリームチーズを包み、きなこをまぶしました</span></li>
                      </>
                    }
                  </ul>
                </div>

                <div className={styles.menuItem}>
                  <div className={styles.menuItemImageWrap}>
                    <StaticImage
                      className={styles.menuItemImage}
                      src="https://dounutbiyori.blob.core.windows.net/images/menu/limited-menu/okunoto-shiozenzai.png"
                      alt=""
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                  <ul className={styles.menuTextList}>
                    {breakPoints.pc &&
                      <>
                        <li className={`fwb ${styles.menuFlavorText}`}><span>奥能登の塩ぜんざい</span></li>
                        <li className={styles.menuDetail}><span>ごま香る生地にたっぷりの粒あんと</span></li>
                        <li className={styles.menuDetail}><span>求肥を包みました</span></li>
                      </>
                    }
                    {breakPoints.sp &&
                      <>
                        <li className={`fwb ${styles.menuFlavorText}`}><span>奥能登の塩ぜんざい</span></li>
                        <li className={styles.menuDetail}><span>ごま香る生地にたっぷりの粒あんと求肥を包みました</span></li>
                      </>
                    }
                  </ul>
                </div>

                <div className={styles.menuItem}>
                  <div className={styles.menuItemImageWrap}>
                    <StaticImage
                      className={styles.menuItemImage}
                      src="https://dounutbiyori.blob.core.windows.net/images/menu/limited-menu/goroujima-kintoki-tarto.png"
                      alt=""
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                
                  <ul className={styles.menuTextList}>
                    {breakPoints.pc &&
                      <>
                        <li className={`fwb ${styles.menuFlavorText}`}><span>五郎島金時たると</span></li>
                        <li className={styles.menuDetail}><span>こっぼこぼの五郎島金時と一緒に</span></li>
                        <li className={styles.menuDetail}><span>豆乳クリームとクリームチーズを包みました</span></li>
                      </>
                    }
                    {breakPoints.sp &&
                      <>
                        <li className={`fwb ${styles.menuFlavorText}`}><span>五郎島金時たると</span></li>
                        <li className={styles.menuDetail}><span>こっぼこぼの五郎島金時と一緒に豆乳クリームとクリームチーズを包みました</span></li>
                      </>
                    }
                  </ul>
                </div>

                <div className={styles.menuItem}>
                  <div className={styles.menuItemImageWrap}>
                    <StaticImage
                      className={styles.menuItemImage}
                      src="https://dounutbiyori.blob.core.windows.net/images/menu/limited-menu/goroujima-kintoki-tiramisu.png"
                      alt=""
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                  <ul className={styles.menuTextList}>
                    {breakPoints.pc &&
                      <>
                        <li className={`fwb ${styles.menuFlavorText}`}><span>五郎島金時のティラミス風</span></li>
                        <li className={styles.menuDetail}><span>コーヒー生地にこっぼこぼの五郎島金時と</span></li>
                        <li className={styles.menuDetail}><span>チーズクリームを包みました</span></li>
                      </>
                    }
                    {breakPoints.sp &&
                      <>
                        <li className={`fwb ${styles.menuFlavorText}`}><span>五郎島金時のティラミス風</span></li>
                        <li className={styles.menuDetail}><span>コーヒー生地にこっぼこぼの五郎島金時とクリームを包みました</span></li>
                      </>
                    }
                  </ul>
                </div>

            </div>
          </div>
        </section>

        {/* Classic Donuts Menu */}
        <section className={`jp ${styles.classicMenu}`}>
          <div className={styles.donutsMenu}>
            <h2 className={styles.secTitle}>定番商品</h2>
            {breakPoints.pc && 
              <div className={styles.menuContents}>
                {items.map((item: any) => (
                  <div className={styles.menuItem} key={item.key}>
                    <div className={styles.menuItemImageWrap}>
                      <GatsbyImage image={getImage(item.image_url)!} alt="" className={styles.menuItemImage}/>
                    </div>
                    <ul className={styles.menuTextList}>
                      <>
                        <li className={`fwb ${styles.menuFlavorText}`}><span>{item.flavor}</span></li>
                        <li className={styles.menuDetail}><span>{item.detailTop}</span></li>
                        <li className={styles.menuDetail}><span>{item.detailBtm}</span></li>
                      </>
                    </ul>
                  </div>
                ))}
              </div>
            }

            {breakPoints.sp && 
              <div className={styles.menuContents}>
                <Swiper pagination={pagination} className="menuSwiper">
                  <SwiperSlide>
                    {spItems.silide1.map((item: any) => (
                      <div className={styles.menuItem} key={item.key}>
                        <div className={styles.menuItemImageWrap}>
                          <GatsbyImage image={getImage(item.image_url)!} alt="" className={styles.menuItemImage}/>
                        </div>
                        <ul className={styles.menuTextList}>
                          {breakPoints.sp &&
                            <>
                              <li className={`fwb ${styles.menuFlavorText}`}><span>{item.flavor}</span></li>
                              <li className={styles.menuDetail}><span>{item.detailTop}</span><span>{item.detailBtm}</span></li>
                            </>
                          }
                        </ul>
                      </div>
                    ))}
                  </SwiperSlide>
                  <SwiperSlide>
                    {spItems.silide2.map((item: any) => (
                      <div className={styles.menuItem} key={item.key}>
                        <div className={styles.menuItemImageWrap}>
                          <GatsbyImage image={getImage(item.image_url)!} alt="" className={styles.menuItemImage}/>
                        </div>
                        <ul className={styles.menuTextList}>
                          {breakPoints.sp &&
                            <>
                              <li className={`fwb ${styles.menuFlavorText}`}><span>{item.flavor}</span></li>
                              <li className={styles.menuDetail}><span>{item.detailTop}</span><span>{item.detailBtm}</span></li>
                            </>
                          }
                        </ul>
                      </div>
                    ))}
                  </SwiperSlide>
                  <SwiperSlide>
                    {spItems.silide3.map((item: any) => (
                      <div className={styles.menuItem} key={item.key}>
                        <div className={styles.menuItemImageWrap}>
                          <GatsbyImage image={getImage(item.image_url)!} alt="" className={styles.menuItemImage}/>
                        </div>
                        <ul className={styles.menuTextList}>
                          {breakPoints.sp &&
                            <>
                              <li className={`fwb ${styles.menuFlavorText}`}><span>{item.flavor}</span></li>
                              <li className={styles.menuDetail}><span>{item.detailTop}</span><span>{item.detailBtm}</span></li>
                            </>
                          }
                        </ul>
                      </div>
                    ))}
                  </SwiperSlide>
                  <SwiperSlide>
                    {spItems.silide4.map((item: any) => (
                      <div className={styles.menuItem} key={item.key}>
                        <div className={styles.menuItemImageWrap}>
                          <GatsbyImage image={getImage(item.image_url)!} alt="" className={styles.menuItemImage}/>
                        </div>
                        <ul className={styles.menuTextList}>
                          {breakPoints.sp &&
                            <>
                              <li className={`fwb ${styles.menuFlavorText}`}><span>{item.flavor}</span></li>
                              <li className={styles.menuDetail}><span>{item.detailTop}</span><span>{item.detailBtm}</span></li>
                            </>
                          }
                        </ul>
                      </div>
                    ))}
                  </SwiperSlide>
                </Swiper>
              </div>
            }
          </div>
        </section>

        {/* Drink Menu */}
        <section className={`jp ${styles.drinkMenu}`} id='drinkMenu'>
          <h2 className={styles.secTitle}>ドリンクメニュー</h2>
          <div className={styles.menuContents}>
            {drinkMenu.map((drinkItem: any) => (
              <div className={styles.drinkMenuItem} key={drinkItem.key}>
                <p className={`en ${styles.drinkType}`}>{drinkItem.type}</p>
                <div className={styles.horizontalLine}></div>
                  {drinkItem.item.map((menuItem: string) => (
                    <p className={styles.drink} key={menuItem}>{menuItem}</p>
                  ))}
              </div>
            ))}
          </div>
        </section>

        {/* Way TO Eat */}
        <section className={`jp ${styles.wayToEat}`} id='wayToEat'>
          <h2 className={styles.secTitle}>美味しいお召し上がり方</h2>
          <div className={styles.wayToEatContents}>
            <ul className={styles.itemList}>
              <li className={styles.innerItem}>
                  <div className={styles.innerHeader}>
                    <StaticImage
                      className={styles.innerHeaderIcon}
                      src="https://dounutbiyori.blob.core.windows.net/images/top/point_icon.png"
                      alt=""
                      formats={["auto", "webp", "avif"]}
                    />
                    <p className={`fwb ${styles.innerHeaderTitle}`}>おいしい食べ方</p>
                  </div>
                  <div className={styles.innerBody}>
                    <ul className={styles.innerBodyTextList}>
                      <li><span>温めるとさらにおいしくお召し上がりいただけます。</span></li>
                      <li><span>袋から出して電子レンジで温めてください。</span></li>
                      {breakPoints.pc &&
                        <>
                          <li><span>200W  約40秒／500W  約20秒／</span></li>
                          <li><span>600W  約15秒</span></li>
                          <li><span>目安は人肌くらいの温かさです。</span></li>
                        </>
                      }
                      {breakPoints.sp &&
                        <>
                          <li><span>200W  約40秒／500W  約20秒／600W  約15秒</span></li>
                          <li><span>目安は人肌くらいの温かさです。</span></li>
                        </>
                      }
                     
                    </ul>
                  </div>
              </li>

              <li className={styles.innerItem}>
                  <div className={styles.innerHeader}>
                    <StaticImage
                      className={styles.innerHeaderIcon}
                      src="https://dounutbiyori.blob.core.windows.net/images/top/point_icon.png"
                      alt=""
                      formats={["auto", "webp", "avif"]}
                    />
                    <p className={`fwb ${styles.innerHeaderTitle}`}>冷凍保存もおすすめ</p>
                  </div>
                  <div className={styles.innerBody}>
                    <ul className={styles.innerBodyTextList}>
                      <li><span>ご購入日翌日以降にお召し上がりの場合、ご購入日当日のうちに冷凍保存がおすすめです。</span></li>
                      <li><span>ビニール袋やラップにひとつずつ包んで冷凍してください。保存期間は1ヶ月です。</span></li>
                    </ul>
                  </div>
              </li>

              <li className={styles.innerItem}>
                  <div className={styles.innerHeader}>
                    <StaticImage
                      className={styles.innerHeaderIcon}
                      src="https://dounutbiyori.blob.core.windows.net/images/top/point_icon.png"
                      alt=""
                      formats={["auto", "webp", "avif"]}
                    />
                    <p className={`fwb ${styles.innerHeaderTitle}`}>解凍方法</p>
                  </div>
                  <div className={styles.innerBody}>
                    <ul className={styles.innerBodyTextList}>
                      <li><span>袋のまま自然解凍（目安は2時間程度）。解凍後は必ず温めてからお召し上がりください。</span></li>
                      <li><span>袋から出して電子レンジで温めてください。</span></li>
                      {breakPoints.pc &&
                        <>
                          <li><span>200W  約40秒／500W  約20秒／</span></li>
                          <li><span>600W  約15秒</span></li>
                          <li><span>目安は人肌くらいの温かさです。</span></li>
                        </>
                      }
                      {breakPoints.sp &&
                        <>
                          <li><span>200W  約40秒／500W  約20秒／600W  約15秒</span></li>
                          <li><span>目安は人肌くらいの温かさです。</span></li>
                        </>
                      }
                    </ul>
                  </div>
              </li>
            </ul>
            <ul className={styles.noteList}>
              <li><span>※冷蔵庫に入れないようにお願いします。生地が硬くなり、風味が損なわれてしまいます。</span></li>
              <li><span>※温めすぎると美味しさが半減してしまいますので、温めすぎないようご注意ください。</span></li>
            </ul>
          </div>
        </section>

        {/* SNS */}
        <section className={`jp ${styles.sns}`} id='sns'>
          <div className={styles.snsHeader}>
            <StaticImage
              className={styles.snsHeaderIcon}
              src="https://dounutbiyori.blob.core.windows.net/images/top/Insta_gnavi_hover.png"
              alt=""
              formats={["auto", "webp", "avif"]}
            />
            <p className={`en ${styles.snsHeaderTitle}`}>Instagram</p>
          </div>
          {breakPoints.pc &&
            <p className={styles.snsInfoText}>どうなつ日和の最新ニュースはインスタグラムをチェックしてください。</p>
          }
          {breakPoints.sp && 
            <div className={styles.snsInfoText}>
              <p>どうなつ日和の最新ニュースは</p>
              <p>インスタグラムをチェックしてください。</p>
            </div>
          }
          
          <div className={styles.instagramImageContentsWraper}>
            {posts !== undefined && posts.length > 0 &&
              <div className={styles.instagramImageList}>
                {posts.map((item: Media, index: number) => (
                  <div className={`${styles.imageWrap} ${breakPoints.sp && index > 5 ? styles.spImage : ""}`} key={index}>
                    <a href={item.permalink}>
                      {!item.thumbnail_url &&
                        <img src={item.media_url} className={styles.postImage} alt="" />
                      }
                      {item.thumbnail_url &&
                        <img src={item.thumbnail_url} className={styles.postImage} alt="" />
                      }
                    </a>
                  </div>
                ))}
              </div>
            }
            {posts === undefined &&
                <div className="NoImage">
                </div>
            }
          </div>
        </section>

        {/* Store */}
        <section className={`jp ${styles.store}`} id='store'>
          <h2 className={styles.secTitle}>店舗情報</h2>

          <div className={styles.storeContents}>
            <div className={styles.mapContents}>
              <Map center={{ lat:36.580438, lng: 136.617396 }}/>
            </div>

            <div className={styles.aboutStore}>
              <ul className={styles.storeAboutTextList}>
                { breakPoints.pc && 
                  <>
                    <li><span>920-0069 石川県金沢市戸板西１丁目５５イオンタウン金沢示野内</span></li>
                    <li><span>TEL 076-254-5830</span></li>
                  </>
                }
                { breakPoints.sp && 
                  <>
                    <li><span>920-0069 石川県金沢市戸板西１丁目５5</span></li>
                    <li><span>イオンタウン金沢示野内</span></li>
                    <li><a href="tel:076-254-5830" className={styles.phoneLink}><span>TEL 076-254-5830</span></a></li>
                  </>
                } 
                <li><span>営業時間　10:00-18:00 / 水曜定休</span></li>
                <a href="https://g.page/r/CZu5--1JNbxUEBA" className={`fwd ${styles.btnWrap} ${styles.bgLeft}`}>
                  <p className={styles.btnLabel}>Google Map</p>
                </a>
              </ul>
              <div className={styles.toSnsBtn}>
                <a href="https://www.instagram.com/donutbiyori/" className={styles.instagram}>
                    <StaticImage
                      className={styles.instaLogo}
                      src="https://dounutbiyori.blob.core.windows.net/images/top/Insta_Logo.png"
                      alt=""
                      formats={["auto", "webp", "avif"]}
                    />
                    <ul className={`fwb ${styles.instagramTextList}`}>
                      <li><span>どうなつ日和</span></li>
                      <li><span>Instagram</span></li>
                    </ul>
                </a>
                <ul className={styles.instagramNoteText}>
                  <li><span>最新ニュースはインスタグラムで</span></li>
                  <li><span>発信しています。</span></li>
                </ul>
              </div>
            </div>          
          </div>

          <div className={styles.selfLaundry}>
            <div className={styles.blandItem}>
              <StaticImage
                className={styles.balukoLogo}
                src="https://dounutbiyori.blob.core.windows.net/images/top/BLP_Logo.png"
                alt=""
                formats={["auto", "webp", "avif"]}
              />
            </div>
            <div>
              <ul className={styles.selfLaundryTextList}>
                <li><a className={styles.pageLink} href="https://baluko.jp/baluko-aeontownkanazawashimeno/"><span className={styles.uText}>Baluko Laundry Place イオンタウン金沢示野 併設</span></a></li>
                <li><span className={styles.uText}>24時間営業／年中無休</span></li>
              </ul>
            </div>
          </div>
        </section>
          <div className={styles.storeContentsUeharaWrap}>
              <div className={styles.storeNotice}>■一部の商品はBaluko Laundry Place代々木上原店のBaluko Cafeでも<br className={styles.sp} />お召し上がりいただけます。</div>

              <div className={styles.storeContentsUehara}>
                  <div className={styles.mapContentsHalf}>
                      <Map center={{ lat: 35.66432, lng: 139.674892 }}/>
                  </div>

                  <div className={styles.aboutStore}>
                      <ul className={styles.storeAboutTextList}>
                          { breakPoints.pc &&
                              <>
                                  <li><span><a href={'https://baluko.jp/baluko-uehara/'}>Baluko Laundry Place 代々木上原</a></span></li>
                                  <li><span>151-0064 東京都渋谷区上原3-29-2</span></li>
                                  <li><span>営業時間　9:00-20:00</span></li>
                                  <li><span>定休日なし/年末年始・夏季休業あり</span></li>
                              </>
                          }
                          { breakPoints.sp &&
                              <>
                                  <li><span>151-0064 東京都渋谷区上原3-29-2</span></li>
                                  <li><span>営業時間　9:00-20:00</span></li>
                                  <li><span>定休日なし/年末年始・夏季休業あり</span></li>
                              </>
                          }
                          <a href="https://www.google.com/maps?ll=35.664293,139.674864&z=16&t=m&hl=ja&gl=JP&mapclient=embed&cid=7706277033487140049" className={`fwd ${styles.btnWrap} ${styles.bgLeft}`}>
                              <p className={styles.btnLabel}>Google Map</p>
                          </a>
                      </ul>
                      <div className={styles.toSnsBtn}>
                          <a href="https://www.instagram.com/baluko.yoyogiuehara" className={styles.instagram}>
                              <StaticImage
                                  className={styles.instaLogo}
                                  src="https://dounutbiyori.blob.core.windows.net/images/top/Insta_Logo.png"
                                  alt=""
                                  formats={["auto", "webp", "avif"]}
                              />
                              <ul className={`fwb ${styles.instagramTextList}`}>
                                  <li><span>BLP代々木上原</span></li>
                                  <li><span>Instagram</span></li>
                              </ul>
                          </a>
                          <ul className={styles.instagramNoteText}>
                              <li><span>テイクアウト休業日や</span></li>
                              <li><span>お知らせなどを発信しています。</span></li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </>
    )
})

export default Contents

const query = graphql`
  query Query {
    allItemJson {
      nodes {
        flavor
        detailTop
        detailBtm
        key
        image_url {
            childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allDrinkJson {
      nodes {
        key
        type
        item
      }
    }
  }
`
