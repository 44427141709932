import axios, { AxiosResponse } from 'axios';
import Media from "types/media";

export const FetchApi = async (path :string): Promise<Media[]> => {
    const result = axios.get(path)
      .then((response) => {
        const { data } = response;
        return data.media.data;
      }).catch((e) => {
        console.log(e);
    })
    return result;
  }