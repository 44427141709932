import { useState, useEffect, useRef } from "react"
import axios, { AxiosResponse } from "axios"
import { FetchApi } from "services/fetch"
import Media from "types/media"

const useGetPosts = () => {
  const [posts, setPosts] = useState<Media[]>([])
  useEffect(() => {
    const getAsyncPosts = async () => {
      const path =
        "https://graph.facebook.com/17841454181201295?access_token=EAAuIKZBZCSB78BAGzwZAjy4iUKbFG6Avc384ZA7jjkTFOEYntdRCN6FEw0R48MwNclZBZC1csmrnFSGZBQ1tSBeOJGLys82Gu9oDSSFsSg3ZBxXKYL6VRsTkJujZCaWgbZCzbTb1Rb1asq2naHp9Dsj86xanEuHO6NRX9sdVIi1XvnBkgPuoWuXUk7zQOdM73fwqIZD&fields=name,media.limit(8){caption,media_url,permalink, thumbnail_url}"
      const result = await FetchApi(path)
      setPosts(result)
    }
    getAsyncPosts()
  }, [])
  return posts
}

export default useGetPosts
