import Item from "types/item";

type Result = {
    silide1: Item[],
    silide2: Item[],
    silide3: Item[],
    silide4: Item[]
}

export const convertSpItem = (items: Item[]) => {
    const result: Result = {
      "silide1": [],
      "silide2": [],
      "silide3": [],
      "silide4": []
    };
    items.forEach((item: Item) => {
        if (item.key >= 1  && item.key <= 4) {
            result['silide1'].push(item);
        }
        if (item.key >= 5 && item.key <= 8) {
            result['silide2'].push(item);
        }
        if (item.key >= 9 && item.key <= 12) {
            result['silide3'].push(item);
        }
        if (item.key >= 13 && item.key <= 16) {
            result['silide4'].push(item);
        }
    })
    return result;
}