import React from "react"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const apiKey :string = "AIzaSyDPklIOU5hIdvzLEDtX7y5zQQKBeDGQvCw";

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const Map: React.FC<{ center: google.maps.LatLngLiteral }> = (props) => {

    return (
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={props.center}
            zoom={17}
            options={{
              mapTypeControl: false,
              streetViewControl: false
            }}
          >
           <Marker position={props.center} />
          </GoogleMap>
        </LoadScript>
    );
  };

export default Map;